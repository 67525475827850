export const styles = {
  footerOne: {
    backgroundColor: "#f6f6f6",
    paddingTop: 60,
    paddingBottom: 60,
  },
  footerTwo: {
    backgroundColor: "#1c4c84",
  },
};
