import basic1 from "./basic1.jpg";
import basic2 from "./basic2.jpg";
import basic3 from "./basic3.jpg";
import basic4 from "./basic4.jpg";
import basic5 from "./basic5.jpg";
import basic6 from "./basic6.jpg";
import basic7 from "./basic7.jpg";


const basicDockImgs = [
  {
    src: basic1,
    alt: "Sweetwater Marine Construction Basic Dock"
  },
  {
    src: basic2,
    alt: "Sweetwater Marine Construction Basic Dock"
  },
  {
    src: basic3,
    alt: "Sweetwater Marine Construction Basic Dock"
  },
  {
    src: basic4,
    alt: "Sweetwater Marine Construction Basic Dock"
  },
  {
    src: basic5,
    alt: "Sweetwater Marine Construction Basic Dock"
  },
  {
    src: basic6,
    alt: "Sweetwater Marine Construction Basic Dock"
  },
  {
    src: basic7,
    alt: "Sweetwater Marine Construction Basic Dock"
  },
];

export default basicDockImgs;