import shoremgt1 from "./shoremgt1.jpg";
import shoremgt2 from "./shoremgt2.jpg";
import shoremgt3 from "./shoremgt3.jpg";
import shoremgt4 from "./shoremgt4.jpg";
import shoremgt5 from "./shoremgt5.jpg";

const shoreMgtImages = [
  {
    src: shoremgt1,
    alt: "Sweetwater Marine Construction Shore Management"
  },
  {
    src: shoremgt2,
    alt: "Sweetwater Marine Construction Shore Management"
  },
  {
    src: shoremgt3,
    alt: "Sweetwater Marine Construction Shore Management"
  },
  {
    src: shoremgt4,
    alt: "Sweetwater Marine Construction Shore Management"
  },
  {
    src: shoremgt5,
    alt: "Sweetwater Marine Construction Shore Management"
  }
];

export default shoreMgtImages;